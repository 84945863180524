import { AfterViewInit, Component, inject, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { CommonModule, NgIf } from '@angular/common';
import { AuthService } from '../auth.service';
import { finalize, Subscription, take, tap } from 'rxjs';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CredentialResponse, LoginRequestWithGoogle } from '../../shared/schemas';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../shared/local-storage/local-storage.service';

declare global {
    interface Window {
        handleCredentialResponse: (response: CredentialResponse) => void;
    }
}

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [ReactiveFormsModule, ButtonModule, InputTextModule, PasswordModule, NgIf, RouterModule, CommonModule],
    providers: [AuthService],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
})
export class LoginComponent implements AfterViewInit, OnDestroy {
    private router: Router = inject(Router);

    form: FormGroup<{ phone: FormControl<string>; password: FormControl<string>; otp: FormControl<string> }>;
    loginInProgress = false;
    shouldDisplayOtp = false;
    clientId: string = environment.googleAuthClientId;
    navigationSubscription: Subscription;
    shouldDisplayForm: boolean = environment.isPhoneLoginEnabled;

    constructor(
        private ngZone: NgZone,
        private readonly fb: FormBuilder,
        private readonly authService: AuthService,
        private localStorageService: LocalStorageService,
    ) {
        this.form = this.fb.nonNullable.group({
            phone: ['', [Validators.required, Validators.pattern('^5[0-9]{8}$')]],
            password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
            otp: ['', [Validators.minLength(6), Validators.maxLength(6)]],
        });
        this.navigationSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.loadGoogleSignInScript();
            }
        });
    }

    ngAfterViewInit(): void {
        this.loadGoogleSignInScript();
    }

    ngOnDestroy(): void {
        this.navigationSubscription.unsubscribe();
    }
    requestOtp() {
        this.loginInProgress = true;
        const { password } = this.form.getRawValue();
        const phone = `00966${this.form.controls.phone.value}`;
        this.authService
            .requestLoginOtp({ phone, password, otp_type: 'login' })
            .pipe(
                take(1),
                tap(() => {
                    this.shouldDisplayOtp = true;
                    this.form.controls.otp.addValidators(Validators.required);
                }),
                finalize(() => {
                    this.loginInProgress = false;
                }),
            )
            .subscribe();
    }

    validateOtp() {
        this.loginInProgress = true;
        const { password, otp } = this.form.getRawValue();
        const phone = `00966${this.form.controls.phone.value}`;
        this.authService
            .loginUser({ password }, otp, phone)
            .pipe(
                take(1),
                tap((response) => {
                    this.localStorageService.setTokens(response);
                    this.authService.isLoggedIn.next(true);
                    this.authService.setUserContext(response.access_token);
                    this.router.navigate(['/providers']);
                }),
                finalize(() => {
                    this.loginInProgress = false;
                }),
            )
            .subscribe();
    }

    loadGoogleSignInScript(): void {
        // Remove old script if it exists
        const existingScript = document.getElementById('google-signin-script');
        if (existingScript) {
            existingScript.remove();
        }

        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.id = 'google-signin-script';
        script.async = true;
        script.onload = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const googleAccounts = window.google?.accounts;
            if (googleAccounts) {
                this.ngZone.runOutsideAngular(() => {
                    googleAccounts.id.renderButton(document.querySelector('.g_id_signin'), {
                        type: 'standard',
                        shape: 'rectangular',
                        theme: 'filled_black',
                        text: 'signin_with',
                        size: 'large',
                    });
                });
                window.handleCredentialResponse = (response: CredentialResponse) =>
                    this.handleCredentialResponse(response);
            }
        };
        document.head.appendChild(script);
    }

    handleCredentialResponse(response: CredentialResponse) {
        const body: LoginRequestWithGoogle = {
            credential: response.credential,
        };
        this.authService
            .loginInWithGoogle(body)
            .pipe(
                take(1),
                tap((response) => {
                    this.localStorageService.setTokens(response);
                    this.authService.setUserContext(response.access_token);
                    this.authService.isLoggedIn.next(true);
                    this.router.navigate(['/providers']);
                }),
                finalize(() => {
                    this.loginInProgress = false;
                }),
            )
            .subscribe();
    }
}
