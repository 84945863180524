import { Injectable } from '@angular/core';
import { Observable, of, take, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private router: Router,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
    ) {}

    canActivate(): Observable<boolean> {
        const refreshToken = this.localStorageService.getRefreshToken();
        if (refreshToken && !this.authService.isJwtExpired(refreshToken, 0)) {
            this.authService.isLoggedIn.next(true);
            return of(true);
        }
        return of(false).pipe(
            take(1),
            tap(() => {
                this.authService.isLoggedIn.next(false);
                this.localStorageService.clearTokens();
                this.router.navigate(['/auth']).then();
            }),
        );
    }
}
